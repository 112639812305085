import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layoutSurpriseCustom"
import SEO from "../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi Everyone</h1>
    <p>This is a basic layout for Surprise site.</p>
    <p>
      Now let's look at the rest of the site. These pages will be added when I
      can.
    </p>
  </Layout>
)

export default IndexPage
